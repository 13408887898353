import { startTransition } from 'react';
import { useEffect, useState } from 'react';
import { getCategoriaProducto } from '../repository/productoRepository';

const useCategoria = (codigo) => {
    const [loader, setLoader] = useState(true);
    const [categoria, setCategoria] = useState(null);

    useEffect(() => {
        setLoader(true);

        startTransition(() => {
            getCategoriaProducto(codigo)
            .then(res => {
                setCategoria(res);
            }).catch(() => {
                setCategoria([]);
            }).finally(() => {
                setLoader(false);
            });
        });
    }, []);
    
    return { loader, categoria };
}

export default useCategoria;