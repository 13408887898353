export const DOMINIO         = 'https://www.intelaf.com';
export const URL_API_INTELAF = 'https://api.intelaf.com:2053/app/api';
export const URL_IMG_INTELAF = 'https://api.intelaf.com:2053/cms';

  //=====================CERTIFICACION PASARELAS DE PAGO===========================
  //"https://api.intelaf.com:2053/AppPowerTranz/api"       //Es para PawerTranz
  //"https://api.intelaf.com:2053/AppEpay/api"             //Es para E-pay

  //=================================DEVELOPMENT====================================
  //"https://api.intelaf.com:2053/AppDevelopment/api"      //Es para desarrollo
  //"http://localhost:54448/api"                            //VISUAL STUDIO
  //"http://192.168.220.251:2053/App/api"                   //IIS para desarrollo

  //===================================TESTING======================================
  //"https://api.intelaf.com:2053/AppQA/api"               //Es para pruebas QA
  //"https://api.intelaf.com:2053/AppUAT/api"              //Es para pruebas UAT
  //"https://api.intelaf.com:2053/AppStaging/api"          //Es para pruebas (Stage)

  //=================================PRODUCCION======================================
  //"https://api.intelaf.com:2053/App/api"                 //Es para produccion
