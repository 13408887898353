import { Divider, List, ListItem, ListItemIcon as MuiListItemIcon, ListItemText as MuiListItemText, styled } from '@material-ui/core';
import { Link, useNavigate } from 'react-router-dom';
import React from 'react';
import IconAccountCircle from '../../../icons/IconAccountCircle';
//import IconIntelaf from '../../../icons/IconIntelaf';
import IconClose from '../../../icons/IconClose';
import LogoIntelaf from '../assets/LogoIntelaf';
import ButtonWithIcon from '../../../controls/ButtonWithIcon';
import IconLocalShipping from '../../../icons/IconLocalShipping';
import IconStore from '../../../icons/IconStore';
import IconStoreFront from '../../../icons/IconStoreFront';
import IconSearch from '../../../icons/IconSearch';
import IconFavorite from '../../../icons/IconFavorite';
import IconShoppingCart from '../../../icons/IconShoppingCart';
import IconWidgets from '../../../icons/IconWidgets';
import IconSell from '../../../icons/IconSell';
import IconContactSupport from '../../../icons/IconContactSupport';
import IconPolicy from '../../../icons/IconPolicy';
import IconLocationOn from '../../../icons/IconLocationOn';
import IconDomain from '../../../icons/IconDomain';
import IconInfo from '../../../icons/IconInfo';
import IconWork from '../../../icons/IconWork';
import IconWishlist from '../../../icons/IconWishList';
import useCheckOutHandler from '../../../../redux/handler/useCheckOutHandler';
import useUsuarioHandler from '../../../../redux/handler/useUsuarioHandler';
import { useMetodoDeEntrega } from "../../../../contexts/MetodoDeEntrega";
import metodoDeEnvio from '../../../../enums/metodoDeEnvio';

const MenuMain = ({ onClose, onOpenProductos }) => {
    const navigate = useNavigate();

    const { checkout } = useCheckOutHandler();
    const { usuario } = useUsuarioHandler();
    const { openDialogEntrega } = useMetodoDeEntrega();

    const handleSelectSucursal = () => {
        openDialogEntrega({});
    }

    return (
        <>
            <div style={{ display: 'flex', padding: '15px', justifyContent: 'space-between' }}>
                <LogoIntelaf />
                <IconClose style={{ cursor: 'pointer' }} width={26} height={26} fill={"#808080"} onClick={onClose} />
            </div>
            {
                usuario.IsLogged ? <></> :
                    <div style={{ display: 'flex', padding: '15px', gap: '10px' }}>
                        <ButtonWithIcon
                            iconSize={18}
                            text={'Ingresar'}
                            textSize={14}
                            textWeight={400}
                            height={40}
                            flexDirection={'row-reverse'}
                            className='buttonWithIcon-darkAqua'
                            aria-label={'Ingresar'}
                            type={'button'}
                            onClick={() => navigate('/login')}
                        />
                        <ButtonWithIcon
                            iconSize={18}
                            text={'Registrarme'}
                            textSize={14}
                            textWeight={400}
                            height={40}
                            flexDirection={'row-reverse'}
                            className='buttonWithIcon-whiteghost'
                            aria-label={'Registrarme'}
                            type={'button'}
                            onClick={() => navigate('/registro')}
                        />
                    </div>
            }
            <List>

                <ListItem style={{ background: 'rgba(155, 155, 155, 0.1)' }} button onClick={onOpenProductos}>
                    <ListItemIcon>
                        <IconWidgets width={26} height={26} fill={"#fb5300"} />
                    </ListItemIcon>
                    <ListItemTextOranged style={{color: "#fb5300 !important"}} primary={'Productos'} />
                </ListItem>

                {/* Guia compras */}
                <ListItemLink to="/pages/wishlist">
                    <ListItemIcon>
                        <IconWishlist width={26} height={26} fill={"#fb5300"} />
                    </ListItemIcon>
                    <ListItemTextOranged primary={'Guía de Compras'} />
                </ListItemLink>

                <ListItemLink to="/superofertas">
                    <ListItemIcon>
                        <IconSell width={26} height={26} fill={"#fb5300"} />
                    </ListItemIcon>
                    <ListItemTextOranged primary={'Ofertas'} />
                </ListItemLink>

                {/* Busqueda */}
                <ListItem button onClick={(e) => {
                    e.stopPropagation();
                    onClose();

                    setTimeout(() => {
                        var element = document.getElementById('input-text-search');
                        if (element) {
                            element.focus();
                        }
                    }, 500)
                }}>
                    <ListItemIcon>
                        <IconSearch width={26} height={26} fill={"#fb5300"} />
                    </ListItemIcon>
                    <ListItemTextOranged primary={'Buscar'} />
                </ListItem>

                {/* Metodo de entrega */}
                <ListItem button onClick={handleSelectSucursal}>
                    {
                        checkout.IsCarritoActivo ?
                            <>
                                {
                                    checkout.MetodoDeEnvio === metodoDeEnvio.DOMICILIO ?
                                        <>
                                            <ListItemIcon>
                                                <IconLocalShipping width={26} height={26} fill={"#808080"} />
                                            </ListItemIcon>
                                            <ListItemTextOranged primary={'Envío a Domicilio'} />
                                        </> :
                                        <>
                                            <ListItemIcon>
                                                <IconStore width={26} height={26} fill={"#808080"} />
                                            </ListItemIcon>
                                            <ListItemTextOranged primary={checkout.SucursalDeEntrega.Nombre.toCapitalize()} />
                                        </>
                                }
                            </> :
                            <>
                                <ListItemIcon>
                                    <IconStoreFront width={26} height={26} fill={"#fb5300"} />
                                </ListItemIcon>
                                <ListItemTextOranged primary={'Elegir método de entrega'} />
                            </>
                    }
                </ListItem>

                <Divider style={{ margin: '8px 0px' }} />

                <ListItemLink to="/usuario/cuenta">
                    <ListItemIcon>
                        <IconAccountCircle width={26} height={26} fill={"#808080"} />
                    </ListItemIcon>
                    <ListItemText primary={'Cuenta'} />
                </ListItemLink>

                <ListItemLink to="/usuario/favoritos">
                    <ListItemIcon>
                        <IconFavorite width={26} height={26} fill={"#808080"} />
                    </ListItemIcon>
                    <ListItemText primary={'Favoritos'} />
                </ListItemLink>

                <ListItemLink to="/carrito">
                    <ListItemIcon>
                        <IconShoppingCart width={26} height={26} fill={"#808080"} />
                    </ListItemIcon>
                    <ListItemText primary={'Mi Carrito'} />
                </ListItemLink>

                <Divider style={{ margin: '8px 0px' }} />


                <ListItemLink to="/tiendas">
                    <ListItemIcon>
                        <IconLocationOn width={26} height={26} fill={"#808080"} />
                    </ListItemIcon>
                    <ListItemText primary={'Ubicaciones'} />
                </ListItemLink>

                <ListItemLink to="/empleos">
                    <ListItemIcon>
                        <IconWork width={26} height={26} fill={"#808080"} />
                    </ListItemIcon>
                    <ListItemText primary={'Empleo'} />
                </ListItemLink>

                <ListItemLink to="/centros_servicio">
                    <ListItemIcon>
                        <IconContactSupport width={26} height={26} fill={"#808080"} />
                    </ListItemIcon>
                    <ListItemText primary={'Centros de servicio'} />
                </ListItemLink>

                <ListItemLink to="/politicas">
                    <ListItemIcon>
                        <IconPolicy width={26} height={26} fill={"#808080"} />
                    </ListItemIcon>
                    <ListItemText primary={'Políticas'} />
                </ListItemLink>


                <ListItemLink to="/mayoreo">
                    <ListItemIcon>
                        <IconLocalShipping width={26} height={26} fill={"#808080"} />
                    </ListItemIcon>
                    <ListItemText primary={'Mayoreo'} />
                </ListItemLink>


                <ListItemLink to="/empresa">
                    <ListItemIcon>
                        <IconDomain width={26} height={26} fill={"#808080"} />
                    </ListItemIcon>
                    <ListItemText primary={'Empresa'} />
                </ListItemLink>

                <ListItemLink to="/sugerencias">
                    <ListItemIcon>
                        <IconInfo width={26} height={26} fill={"#808080"} />
                    </ListItemIcon>
                    <ListItemText primary={'Dudas y Sugerencias'} />
                </ListItemLink>


            </List>
        </>
    )
}


const ListItemLink = ({ children, ...props }) => {
    return (
        <ListItem button component={Link} {...props}>
            {children}
        </ListItem>
    )
}

const ListItemIcon = styled(MuiListItemIcon)({
    '&.MuiListItemIcon-root': {
        minWidth: '50px !important'
    }
});

const ListItemText = styled(MuiListItemText)({
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    fontWeight: '400',
    fontSize: '45px',
    fontFamily: 'Inter',
    color: 'rgb(155, 155, 155)',
    '& .MuiTypography-body1': {
        fontSize: '15px',
        fontFamily: 'Inter',
    }
});

const ListItemTextOranged = styled(MuiListItemText)({
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    fontWeight: '400',
    fontSize: '45px',
    fontFamily: 'Inter',
    color: '#fb5300',
    '& .MuiTypography-body1': {
        fontSize: '15px',
        fontFamily: 'Inter',
    }
});

export default MenuMain;
